import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
export const getGramPanchayat = createAsyncThunk('gramPanchayatSlice/getGramPanchayat', async () => {
    try {
        let response = await axios.get('/gram-panchayat', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createGramPanchayat = createAsyncThunk(
    'gramPanchayatSlice/createGramPanchayat',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/gram-panchayat', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Gram Panchayat added successfully.');
            dispatch(getGramPanchayat());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const updateGramPanchayat = createAsyncThunk(
    'gramPanchayatSlice/updateGramPanchayat',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/gram-panchayat/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Gram Panchayat updated successfully.');
            dispatch(getGramPanchayat());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const deleteGramPanchayat = createAsyncThunk('gramPanchayatSlice/deleteGramPanchayat', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/gram-panchayat/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Gram Panchayat deleted successfully.');
        dispatch(getGramPanchayat());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('gramPanchayatSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/gram-panchayat/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getGramPanchayat());
        toast.success(response?.data?.message || 'Gram Panchayat status updated successfully !');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getGramPunchayatOptions = createAsyncThunk('gramPanchayatSlice/getGramPunchayatOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/gram-panchayat/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const gramPanchayatSlice = createSlice({
    name: 'gramPanchayatSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getGramPanchayat.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getGramPunchayatOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default gramPanchayatSlice.reducer;
