import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Loader Components
import showLoader from '../../../../@core/components/loader';
import queryString from 'query-string';
export const getNagarPanchayat = createAsyncThunk('nagarPanchayatSlice/getNagarPanchayat', async () => {
    try {
        let response = await axios.get('/nagar-panchayat', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createNagarPanchayat = createAsyncThunk(
    'nagarPanchayatSlice/createNagarPanchayat',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/nagar-panchayat', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'City added successfully.');
            dispatch(getNagarPanchayat());

            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const updateNagarPanchayat = createAsyncThunk(
    'nagarPanchayatSlice/updateNagarPanchayat',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/nagar-panchayat/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'City updated successfully.');
            dispatch(getNagarPanchayat());

            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const deleteNagarPanchayat = createAsyncThunk('nagarPanchayatSlice/deleteNagarPanchayat', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/nagar-panchayat/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Nagar Panchayat deleted successfully.');
        dispatch(getNagarPanchayat());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('nagarPanchayatSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/nagar-panchayat/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getNagarPanchayat());
        toast.success(response?.data?.message || 'Nagar Panchayat status updated successfully !');

        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getNagarPanchayatOptions = createAsyncThunk('nagarPanchayatSlice/getNagarPanchayatOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/nagar-panchayat/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const nagarPanchayatSlice = createSlice({
    name: 'nagarPanchayatSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getNagarPanchayat.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getNagarPanchayatOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default nagarPanchayatSlice.reducer;
