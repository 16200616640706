import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';

export const getAllBoothPage = createAsyncThunk('boothPageSlice/getAllBoothPage', async (data) => {
    try {
        let response = await axios.post('/booth-page/with-filters', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBoothPageOptions = createAsyncThunk('boothPageSlice/getBoothPageOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/booth-page/options?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBoothPage = createAsyncThunk('boothPageSlice/createBoothPage', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.post('/booth-page', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Booth added successfully.');
        // await dispatch(getAllBoothPage());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateBoothPage = createAsyncThunk('boothPageSlice/updateBoothPage', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/booth-page/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Booth updated successfully.');
        // dispatch(getAllBoothPage());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteBoothPage = createAsyncThunk('boothPageSlice/deleteBoothPage', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/booth-page/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Booth Page deleted successfully.');
        // await dispatch(getAllBoothPage());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('boothPageSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/booth-page/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        // dispatch(getAllBoothPage());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const boothPageSlice = createSlice({
    name: 'boothPageSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getAllBoothPage.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBoothPageOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default boothPageSlice.reducer;
