import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
export const getTaluka = createAsyncThunk('talukaSlice/getTaluka', async () => {
    try {
        let response = await axios.get('/taluka', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createTaluka = createAsyncThunk('talukaSlice/createTaluka', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/taluka', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Taluka added successfully.');
        dispatch(getTaluka());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateTaluka = createAsyncThunk('talukaSlice/updateTaluka', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/taluka/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Taluka updated successfully.');
        dispatch(getTaluka());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteTaluka = createAsyncThunk('talukaSlice/deleteTaluka', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/taluka/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Taluka deleted successfully.');
        dispatch(getTaluka());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('talukaSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/taluka/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getTaluka());
        toast.success(response?.data?.message || 'Taluka status updated successfully !');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTalukaOptions = createAsyncThunk('talukaSlice/getTalukaOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/taluka/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const talukaSlice = createSlice({
    name: 'talukaSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getTaluka.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getTalukaOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default talukaSlice.reducer;
