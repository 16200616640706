import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getSchemeCategory = createAsyncThunk('schemeCategorySlice/getSchemeCategory', async () => {
    try {
        let response = await axios.get('/schema-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSchemeCategory = createAsyncThunk(
    'schemeCategorySlice/createSchemeCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/schema-category', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Scheme Category added successfully.');
            dispatch(getSchemeCategory());

            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const updateSchemeCategory = createAsyncThunk(
    'schemeCategorySlice/updateSchemeCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/schema-category/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Scheme Category updated successfully.');
            dispatch(getSchemeCategory());

            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const deleteSchemeCategory = createAsyncThunk('schemeCategorySlice/deleteSchemeCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/schema-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Scheme Category deleted successfully.');
        await dispatch(getSchemeCategory());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('schemeCategorySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/schema-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Scheme Category Status updated successfully !');
        dispatch(getSchemeCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// export const getLokSabhaOptions = createAsyncThunk('schemeCategorySlice/getLokSabhaOptions', async () => {
//     try {
//         let response = await axios.get('/lok-sabha/options', {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

export const schemeCategorySlice = createSlice({
    name: 'schemeCategorySlice',
    initialState: {
        data: []
        // options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSchemeCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        // builder.addCase(getLokSabhaOptions.fulfilled, (state, action) => {
        //     state.options = action?.payload;
        //     return;
        // });
    }
});

export default schemeCategorySlice.reducer;
