import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
export const getPanchayatSumittee = createAsyncThunk('panchayatSumitteeSlice/getPanchayatSumittee', async () => {
    try {
        let response = await axios.get('/panchayat-sumittee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createPanchayatSumittee = createAsyncThunk(
    'panchayatSumitteeSlice/createPanchayatSumittee',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/panchayat-sumittee', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Panchayat Sumittee added successfully.');
            dispatch(getPanchayatSumittee());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const updatePanchayatSumittee = createAsyncThunk(
    'panchayatSumitteeSlice/updatePanchayatSumittee',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/panchayat-sumittee/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Panchayat Sumittee updated successfully.');
            dispatch(getPanchayatSumittee());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const deletePanchayatSumittee = createAsyncThunk(
    'panchayatSumitteeSlice/deletePanchayatSumittee',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/panchayat-sumittee/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Panchayat Sumittee deleted successfully.');
            dispatch(getPanchayatSumittee());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);
export const updateStatus = createAsyncThunk('panchayatSumitteeSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/panchayat-sumittee/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getPanchayatSumittee());
        toast.success(response?.data?.message || 'Panchayat Sumittee status updated successfully !');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPunchayatSummitteeOptions = createAsyncThunk('panchayatSumitteeSlice/getPunchayatSummitteeOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/panchayat-sumittee/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const panchayatSumitteeSlice = createSlice({
    name: 'panchayatSumitteeSlice',
    initialState: {
        data: [],
        lokSabhaOptions: [],
        vidhanSabhaOptions: [],
        zillaParisadOptions: [],
        nagarParisadOptions: [],
        nagarPanchayatOptions: [],
        talukaOptions: [],
        gramPanchayatOptions: []
    },

    extraReducers: (builder) => {
        builder.addCase(getPanchayatSumittee.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getPunchayatSummitteeOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default panchayatSumitteeSlice.reducer;
