import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getInfluencerCategory = createAsyncThunk('influencerCategorySlice/getInfluencerCategory', async () => {
    try {
        let response = await axios.get('/influencer-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createInfluencerCategory = createAsyncThunk(
    'influencerCategorySlice/createInfluencerCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/influencer-category', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Influencer Category added successfully.');
            dispatch(getInfluencerCategory());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const updateInfluencerCategory = createAsyncThunk(
    'influencerCategorySlice/updateInfluencerCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/influencer-category/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Influencer Category updated successfully.');
            dispatch(getInfluencerCategory());
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const deleteInfluencerCategory = createAsyncThunk(
    'influencerCategorySlice/deleteInfluencerCategory',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/influencer-category/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Influencer Category deleted successfully.');
            await dispatch(getInfluencerCategory());
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);
export const updateStatus = createAsyncThunk('influencerCategorySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/influencer-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Influencer Category Status updated successfully!');
        dispatch(getInfluencerCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getInfluencerCategoryOptions = createAsyncThunk('influencerCategorySlice/getInfluencerCategoryOptions', async () => {
    try {
        let response = await axios.get('/influencer-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const influencerCategorySlice = createSlice({
    name: 'influencerCategorySlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getInfluencerCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getInfluencerCategoryOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default influencerCategorySlice.reducer;
