// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import moduleSlice from '../views/Setup/ModuleManage/store';
import lokSabha from '../views/Constituency/LokSabha/store/index';
import masterYear from '../views/Master/YearMaster/store';
import election from '../views/ManageElection/Election/Store/index';
import electionParty from '../views/Master/ElectionParty/store/index';
import electionPartyNomination from '../views/ManageElection/ElectionPartyNomination/store/index';
import surveyType from '../views/ManageElection/SurveyType/store/index';
import survey from '../views/ManageElection/Survey/store/index';
import zillaParisad from '../views/Constituency/ZillaParisad/store/index';
import boothPage from '../views/Constituency/PageNo/store';
import punchayatSumittee from '../views/Constituency/PanchayatSumittee/store/index';
import booth from '../views/Constituency/Booth/store/index';
import vidhanSabha from '../views/Constituency/VidhanSabha/store/index';
import village from '../views/Constituency/Village/store';
import nagarParisad from '../views/Constituency/NagarParishad/store/index';
import nagarPanchayat from '../views/Constituency/NagarPanchayat/store/index';
import taluka from '../views/Constituency/Taluka/store/index';
import gramPanchayat from '../views/Constituency/GramPanchayat/store/index';
import leader from '../views/ManageLeaders/Leader/store/index';
import cast from '../views/Master/Cast/store/index';
import influencerCategory from '../views/Master/InfluencerCategory/store/index';
import schemaCategory from '../views/Master/SchemeCategory/store/index';
import voter from '../views/Voter/store/index';
import manageInfluence from '../views/Voter/VoterProfile/Tabs/ManageInfluence/store/index';
import Dashboard from '../views/Dashboard/store/index';
import appBanner from '../views/AppBanner/Banner/store/index';
const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    moduleSlice,
    lokSabha,
    masterYear,
    election,
    electionParty,
    electionPartyNomination,
    surveyType,
    survey,
    zillaParisad,
    boothPage,
    punchayatSumittee,
    booth,
    vidhanSabha,
    village,
    nagarParisad,
    nagarPanchayat,
    taluka,
    gramPanchayat,
    leader,
    cast,
    influencerCategory,
    schemaCategory,
    voter,
    manageInfluence,
    Dashboard,
    appBanner
};

export default rootReducer;
