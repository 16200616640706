import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getLokSabha = createAsyncThunk('lokSabhaSlice/getLokSabha', async () => {
    try {
        let response = await axios.get('/lok-sabha', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLokSabha = createAsyncThunk('lokSabhaSlice/createLockSabha', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/lok-sabha', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Lok Sabha added successfully.');
        dispatch(getLokSabha());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateLokSabha = createAsyncThunk('lokSabhaSlice/updateLockSabha', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/lok-sabha/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Lock Sabha updated successfully.');
        dispatch(getLokSabha());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteLokSabha = createAsyncThunk('lokSabhaSlice/deleteLokSabha', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/lok-sabha/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Lock Sabha deleted successfully.');
        await dispatch(getLokSabha());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('lokSabhaSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/lok-sabha/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getLokSabha());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLokSabhaOptions = createAsyncThunk('lokSabhaSlice/getLokSabhaOptions', async () => {
    try {
        let response = await axios.get('/lok-sabha/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const lokSabhaSlice = createSlice({
    name: 'lokSabhaSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getLokSabha.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getLokSabhaOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default lokSabhaSlice.reducer;
