import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// Loader Components
import showLoader from '@components/loader';

export const getPredictionoptions = createAsyncThunk('dashboardSlice/getPredictionoptions', async (id) => {
    try {
        let response = await axios.get('/dashboard/election-prediction/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPreElection = createAsyncThunk('dashboardSlice/getPreElection', async (id) => {
    try {
        let response = await axios.get('/dashboard/election-pre-election/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getOnElection = createAsyncThunk('dashboardSlice/getOnElection', async (id) => {
    try {
        let response = await axios.get('/dashboard/election-on-election/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        data: [],
        preElection: [],
        onElection: []
    },

    extraReducers: (builder) => {
        builder.addCase(getPredictionoptions.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getPreElection.fulfilled, (state, action) => {
            state.preElection = action?.payload;
            return;
        });
        builder.addCase(getOnElection.fulfilled, (state, action) => {
            state.onElection = action?.payload;
            return;
        });
    }
});

export default dashboardSlice.reducer;
